<template>
  <div class="container">
    <!-- 头部区域 -->
    <Header :headerData={} />

    <!-- Part: 1 -->
    <el-row :gutter="10" justify="center" align="middle">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <!-- <el-row justify="end"> -->
          <div class="panel">
            <h1 class="main-title">About YSA</h1>
            <p style="margin: 16px 0px;">
              Young Scientist Association (YSA) is an inclusive scientific research and journal community for young researchers. Even come from young students, 
              all rigorous science deserves to be published and should be discoverable, widely expressed and freely accessible to all.</p>
            <el-row>
              <el-button type="primary" icon="el-icon-medal-1" size="medium"  @click="$router.push('/v1i1')">2022</el-button>
              <el-button type="primary" icon="el-icon-medal-1" size="medium"  @click="$router.push('/v2i1')">2023</el-button>
              <el-button type="primary" icon="el-icon-medal-1" size="medium"  @click="$router.push('/v3i1')">2024</el-button>
              <div style="color:darkgrey; font-size:small;">Previous journals</div>
              <el-button plain size="medium" @click="clickLogin" style="display:none">login in</el-button>
            </el-row>
          </div>          
        <!-- </el-row> -->
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <!-- 3D立方体相册 -->
        <SectAlbum3D/>
      </el-col>
    </el-row>

    <!-- Part: 2 -->
    <el-row justify="center">
      <div class="sect-title">AIMS AND SCOPE</div>
      <div class="sect-desc">
        <p>
          YSA was founded by a group of active academic researchers from different fields, including biologists, chemists, physicists, computer scientists, 
          and field ecologists. The association aims at providing STEAM education and chances to primary or secondary school students to conduct scientific experiments with scientists. 
          It is the target of the association to open up student's mind to the vivid world of science; to enlighten students, direct them to raise scientific questions 
          and design experiments; to emphasize the importance of data reproducibility as the core of scientific experiments; to demonstrate how to apply cutting-edge 
          computational techniques such as machine learning, supervised and unsupervised autonomous data-analysis to resolve scientific challenges with novel solutions. 
        </p>
        <p>
          The Young Scientist is a peer-reviewed scientific journal dedicated to the publication of research articles, perspectives and other academic articles written by high school and college students. Each article published in The Young Scientist will be critically reviewed by invited professionals of the relevant field before being accepted for publication. Each published article will be assigned with a DOI number such that anyone of interested can easily assess the article. 
        </p>
        <p>
          We welcomes original research submissions from the natural sciences, medical research, engineering, as well as the related social sciences and humanities, 
          including interdisciplinary research.
        </p>
      </div>
   </el-row>
    <el-row>
      <SectPicTxtPlain1/>
    </el-row>

    <!-- Part: 2.5 -->
    <el-row justify="center">
      <div class="sect-title">Why Choose YSA </div>
      <div class="sect-desc">
        <p>
          Our world doesn't want students merely with good grades; instead we seek an obsession, or enthusiasm for the subject they are studying. 
          This motivates them to explore: through extracurricular books, participating in relevant competitions, doing internships and so on. 
          Another important thing is to think out of the box. Be creative in studying, and enjoy what you learn. 
        </p>
        <p>
          Students strive to be successful at publications, and with Y.S.A., authors aspiring to publish will receive scholarly feedback after 
          the reviews of their submissions are received. This feedback will help authors identify areas of improvement to their submission and 
          help them better understand the process to be successful at publication.  Once published, we strive to provide a global platform for 
          our authors to showcase their work.
        </p>
      </div>
     </el-row>
    <el-row>
      <SectPicTxtPlain/>
    </el-row>
   

    <!-- Part: 3 -->
    <el-row justify="center">
      <div class="sect-title">Most Recent Research</div>
      <div class="sect-desc">
        <p>
          Research Articles present the results of original research that address a clearly defined research question and contribute to the body of academic knowledge.
          Y.S.A. considers Research Articles in all areas of the natural sciences, biology, environment, and computing, as well as the related social sciences and humanities.
        </p>
        <p>
          We also recommend some long-term research projects suitable for young students from the research institution's topics. These projects engaging every student.
        </p>
      </div>
      <!-- <div class="sect-desc">The Harvard University logo is a shield containing the Latin slogan “VERITAS” (“truth” or “verity”) on three books. Harvard adopted the Latin “Veritas Christo et Ecclesiae” motto in 1692, which means “Truth for the Church and Christ.” This slogan was placed on a shield and is found on many premises around the main campus, including Memorial Church, Widener Library, and a number of dorms within the Harvard Yard.</div> -->
    </el-row>
    <el-row>
      <SectPicTxt />
    </el-row>


    <!-- Part: 4 -->
    <el-row justify="center">
      <div class="sect-title">Faculty-Refereed Review Process</div>
      <div class="sect-desc">
        <p>
          The acceptance criteria for all papers are the quality and originality of the research and its significance to journal readership. Papers will only 
          be sent to review if the Editor-in-Chief determines that the paper meets the appropriate quality and relevance requirements. 
        </p>
        <p>
          This journal uses a double-blind review, which means that both the reviewer and author identities are concealed from the reviewers, and vice versa, 
          throughout the review process. Authors need to ensure that their manuscripts do not give away their identity to facilitate this. To find out more 
          about the review process, please visit the Author Guidelines page. We invite teachers and faculty interested in reviewing articles for this journal; 
          please visit our Reviewers page for more information.
        </p>
      </div>
    </el-row>
    <!-- <el-row>
      <SectHover></SectHover>
    </el-row> -->


    <!-- Part: 5 -->
    <el-row justify="center">
      <div class="sect-title">Open Access Policy</div>
      <div class="sect-desc">
        <p>
          All articles published in this journal are under a Creative Commons License and the authors retain the copyright to their work.
          <a class="txt-link" href="https://creativecommons.org/licenses/by-nc-sa/4.0/">Licensed under a Creative Commons Attribution-NonCommercial-ShareAlike 4.0 Unported License.</a>
        </p>
        <p>Most publishers own the rights to the articles in their journals. Anyone who wants to read the articles must pay to access them. 
          Anyone who wants to use the articles in any way must obtain permission from the publisher and is often required to pay an additional fee.</p>
        <p>Paying for access to content makes sense in the world of print publishing, where providing content to each new reader requires the production of an additional copy, 
          but online it makes much less sense to charge for content when it is possible to provide access to all readers anywhere in the world.</p>
        <p>Accelerated discovery. With open access, researchers can read and build on the findings of others without restriction.</p>
        <p>Improved education. Open access means that teachers and their students have access to the latest research findings throughout the world.</p>
      </div>
    </el-row>
    <el-row justify="center">
      <!-- <SectPicTxt /> -->
    </el-row>

    <!-- Part: 4 -->
    <el-row justify="center">
      <div class="sect-title">Authors Retain Copyright</div>
      <div class="sect-desc">
        <p>
          All articles published in this journal are under a <a class="" href="https://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons License</a> and the authors retain the copyright to their work.
        </p>
      </div>
    </el-row>
    <!-- <el-row>
      <SectHover></SectHover>
    </el-row> -->



    <!-- Part: 6 -->
    <el-row justify="center">
      <div class="sect-title">Editorial Team</div>
      <div class="sect-desc">
        The Y.S.A. Editorial Board is powered by many of academic experts from all kinds of scientific research area.
        Our board comprises working scientists who are established principal investigators/group leaders with extensive publication records.
        The Chief Editors oversee the peer review process for the journal, including evaluating submissions, selecting reviewers and assessing their comments, and making editorial decisions.
        Together with fellow Editorial Board Members and internal staff, Academic Editors uphold journal policies and ethics standards and work to promote 
        the Y.S.A. mission to provide free public access to scientific research.</div>
    </el-row>
    <el-row>
      <div class="sect-desc">
        <p>
          <a class="" href="#/forauthors">See more information about authors guidance...</a>
        </p>
      </div>
    </el-row>
    <el-row justify="center">
      <!-- <SectPicGroup /> -->
    </el-row>



    <!-- Part: 7 -->
    <el-row justify="center">
      <div class="sect-title">How To Submit</div>
      <div class="sect-desc">
        <p>
          Young Scientist Association (YSA) is a Multidisciplinary and Faculty-reviewed journal devoted to the rapid dissemination of current student research. 
          The journal seeks papers that are novel, integrative, and written in a way that is accessible to a wide audience which includes an array of disciplines. 
          The content of the journal ranges from Applied research to Theoretical research. In general, papers are welcomed from all topics.
        </p>
        <p>
          The first author of the submission must be an Undergraduate or High School student currently enrolled or graduated from an accredited academic institution. 
          In both cases, advisor's information must be listed at submission.
        </p>
        <p>
          A dedicated editor handles your article. Two or more reviewers provide in-depth, thoughtful, constructive reviews.
        </p>
        <p>
          Multiple rounds of review. 30 days to first decision (median). Our submission system is built from the ground up to make submitting, reviewing and editing as easy as possible. 
          This in turn delivers more in-depth reviews, faster, and more often.
        </p>
        <p>
          High School Student Submissions: Please submit the manuscript to info@ysassociation.org, <a class="txt-link" href="#" @click="jumpAuthors"> see more information about author submission requirements.</a>
        </p>
      </div>
    </el-row>

    <!-- Part: 8 -->
    <el-row justify="center">
      <div class="sect-title">Contact US</div>
      <div class="sect-contact">
        <p>
          Office - USA
        </p>
        <p>Address: P.O. Box 68, Ashton, Maryland 20861</p>
        <p>Phone: 301-875-8556</p>
        <p>E-mail: info@ysassociation.org</p>
        <p>Website: www.ysassociation.org</p>
      </div>
    </el-row>


    <el-row justify="center">
    </el-row>


    <!-- 底部 -->
    <Footer />

  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from "../components/Footer.vue";
import SectAlbum3D from '../components/SectAlbum3D.vue'
// import SectPicGroup from '../components/SectPicGroup.vue'
import SectPicTxtPlain1 from '../components/SectPicTxtPlain1.vue'
import SectPicTxtPlain from '../components/SectPicTxtPlain.vue'
import SectPicTxt from '../components/SectPicTxt.vue'     // 杂志封面展示
import SectHover from '../components/SectHover.vue'
// import SectTurnBook from '../components/SectTurnBook.vue'
export default {
  name: "Home",
  data() {
    return {
      isFocus: false,
      liveImg: {
        list:[

        ]
      }
    };
  },

  components: {
    // SectHover,
    SectPicTxt,     // 杂志封面展示
    SectPicTxtPlain,
    SectPicTxtPlain1,
    SectAlbum3D,
    // SectPicGroup,
    Footer,
    Header,
    // SectTurnBook,
  },

  // created() {
  // },

  computed: {
    isSearch() {
      return this.isFocus;
    },
  },

  mounted() {
  },
  

  methods: {
    // 登录点击事件响应函数
    clickLogin(){
      // console.log('大马猴点击了登录按钮！！！！')
      window.location.href ='https://www.daphina.net/planktonDB' ;
    },

    //////
    messageInfo(msg){
      this.$alert(msg, 'Information', {
          confirmButtonText: 'OK',
          callback: action => {
            this.$message({
              type: 'info',
              message: `action: ${ action }`
            });
          }
        });  
    },

    jumpAuthors(){
      window.location.href = "#/forauthors"
    }

  },

};
</script>

<style lang="less" scoped>
.container {
  // margin-bottom: 40px;
  padding-bottom: 0;
}
.panel{
  max-width: 480px;
  margin: 250px auto;
  padding-left: 10px;
  text-align: left ;
  line-height: 1.75 ;
  .main-title {      
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 0px;
  }
  > p{
    font-size: 19px;
    font-weight: 200;
  }
}
.sect-desc{
  text-align: left;
  line-height: 1.75 ;
  padding: 5px 15%;
  font-size: 19px;
  color:#333;
  p::before{
    content: '•';
    margin-right: 5px;
  }
}
.sect-contact{
  text-align: left;
  line-height: 1 ;
  padding: 5px 15%;
  font-size: 12px;
  color:#333;
}
.txt-link{
  font-size: 14px;
}

.main {
  color: #333;
  padding: 0 10%;
  // 中区域的左侧文字区
}

.sect-title {
  max-width: 580px;
  text-align: center;
  margin: 24px auto;
  font-size: 26px;
  font-weight: bold;
}

//显示时按钮样式
.el-button--primary { //需要更改的按钮类型
  background: #2836e0 !important;
  border-color: #2836e0 !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #005eff !important;
  border-color: #005eff !important;
  color: #FFF !important;
}

@media (max-width: 550px) {
  .panel{
    margin: 50px 10px !important;
    h1{
      font-size: 32px !important;
    }
  }
  .sect-desc{
      text-align: left;
      line-height: 1.5 ;
      padding: 5px 15px;
      font-size: 14px;
      color:#333;
      p::before{
        content: '•';
        margin-right: 5px;
    }
  }
  
}
</style>
