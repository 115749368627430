<template>
  <div class="sect-container">
    <el-row type="flex" justify="center">
      <div class="card-section">
        <div class="card" v-for="(item,i) in itemList" :key="i">
          <div class="imgBx">
            <img :src="item.src" alt="">
          </div>
          <div class="content">
            <h2>{{item.title}}</h2>
            <p>{{item.desc}}</p>
          </div>
        </div>     
     
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList:[
        {
          id: 1,
          title: 'Naupulis',
          desc: 'The Nauplius larva is the first planktonic larval form of most marine and of some freshwater crustaceans, from barnacles to anostracans to decapods...',
          src: require('../assets/img/plankton/A03.jpg')
        },
        {
          id: 2,
          title: 'Skeltetonema',
          desc: 'Skeletonema costatum is a species of diatoms in the family Skeletonemataceae. They are native to Bahia, Santa Catarina, Sergipe, Pará, Paraná, ...',
          src: require('../assets/img/plankton/A04.jpg')
        },
        {
          id: 3,
          title: 'Peridinium',
          desc: 'genus of cosmopolitan freshwater dinoflagellates in the family Peridiniaceae, consisting of at least 62 species. Most are found in lakes, ponds...',
          src: require('../assets/img/plankton/A02.jpg')
        },
        {
          id: 4,
          title: 'Pleurosigma',
          desc: 'Pleurosigma is a genus of diatom. The organisms within the genus generally take the shape of an elongated diamond, with regularly pulsating inner organelles. ...',
          src: require('../assets/img/plankton/A16.jpg')
        },
        {
          id: 5,
          title: 'Calanoida',
          desc: 'The calanoids are generally planktonic and require phytoplankton as food. Many harpacticoids are benthic grazers and found on surfaces of grasses or other structures...',
          src: require('../assets/img/plankton/A22.jpg')
        },
        {
          id: 6,
          title: 'Ceratium',
          desc: 'The genus Ceratium is restricted to a small number of freshwater dinoflagellate species. Previously the genus contained large number of marine dinoflagellate species. ...',
          src: require('../assets/img/plankton/A23.jpg')
        },
      ]      
    }
  }
}
</script>


<style lang="less" scoped>
.sect-container{
  margin-bottom: 80px;
}
.card-section {
  position: relative;
  max-width: 1920px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 0;
}
.card-section .card{
  position: relative;
  max-width: 300px;
  height: 215px;
  background: #fff;
  margin: 60px 40px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0,0,0,0.5);
  transition: 0.3s ease-in-out;
}
.card-section .card:hover{
  height: 420px;
}
.card-section .card .imgBx{
  position: relative;
  width: 260px;
  height: 260px;
  top: -60px;
  left: 20px;
  z-index: 1;
  box-shadow: 0 5px 20px rgba(0,0,0,0.2);
}
.card-section .card .imgBx img{
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
}
.card-section .card .content{
  position: relative;
  margin-top: -140px;
  padding: 10px 15px;
  text-align: center;
  color: #111;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.card-section .card:hover .content{
  visibility: visible;
  opacity: 1;
  margin-top: -40px;
  transition-delay: 0.3s;    
}
.card-section .card .content p{
  text-align: left;
}

</style>