<template>
  <div class="box">
    <ul class="minbox" ref="mainbox">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>    
    <audio src=""></audio>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: '',
      a3d: [
        require('../assets/img/a3d_01.jpg'),
        require('../assets/img/a3d_02.jpg'),
        require('../assets/img/a3d_03.jpg'),
        require('../assets/img/a3d_04.jpg'),
        require('../assets/img/a3d_05.jpg'),
        require('../assets/img/a3d_06.jpg'),
        require('../assets/img/a3d_07.jpg'),
        require('../assets/img/a3d_08.jpg'),
        require('../assets/img/a3d_09.jpg'),
        require('../assets/img/a3d_10.jpg'),
        require('../assets/img/havefun_s.gif'),
      ]
    }
  },

  mounted() {
    // this.initA3D();
    // li[0]: 正面
    // li[1]: 对面
    // li[2]: 底面
    // li[3]: 顶面
    // li[4]: 左侧
    // li[5]: 右侧
    // li[1].style.background = `url(${this.a3d[r6]})`;//`#999 url('http://122.112.224.186/Downloads/Data/PICLIB/.thumb/1646234517_762812.jpg') no-repeat or center or cover`;
    this.set3dSrc();
    // this.timer = setInterval(this.resetAlbum3D, 8000);
  },

  methods:{
    async initA3D(){
      let res = await this.$api.plankton.SelectPicture({}); // 根据标签获取所属的全部图片集合  
      if(res.success){
        res.data.forEach(v=>{
          this.a3d.push(this.$common.BASE_URL + '/PICLIB/.thumb/' + v[1]);
        })
      }
      console.log('a3d[]', this.a3d)
    },
    // 设置立方体新数据
    set3dSrc(){
      console.log('set3dSrc()')
      // 产生0-10之间的6个随机数集合      
      let r = this.$common.genRandomSet(10, 6)
      r[4] = 10;
      let li = this.$refs.mainbox.childNodes;
      r.forEach((n,i) => {
        li[i].style.background = `url(${this.a3d[n]})`;
        li[i].style.backgroundSize = '100% 100%';
      });
    },
    // 刷新立方体显示
    resetAlbum3D(){
      console.log('reset 3D structure, and update dom...')
      // 该方法因为非响应式地改变了dom值，需要重新刷新dom
      setTimeout(() => {
        this.set3dSrc();
        // let li = this.$refs.mainbox.childNodes;
        // li[1].style.background = `url(${this.a3d[r2]})`;  // 在8s的循环周期中，li[1]是背面的元素，更换不会导致dom刷新闪动
        // // for(let i=0; i<6; i++){
        // //   li[i].style.backgroundSize = '300px 300px';
        // // }
      }, 0)

    }
  }

}
</script>

<style lang="less" scoped>
.box {
  width: 300px;
  height: 300px;
  margin: 200px 100px;
  // margin: 150px auto;
  transform-style: preserve-3d;
  animation: move 8s infinite linear;
}
.minbox {
  width: 300px;
  height: 300px;
  position: absolute;
  transform-style: preserve-3d;
}
ul {
  list-style: none;
}
.minbox li{
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: #999;
  background-size: 300px 300px;
}
.minbox li:nth-child(1){
  // background: url(../assets/img/a3d_01.jpg);
  transform: translateZ(150px);
}
.minbox li:nth-child(2){
  // background: url(../assets/img/a3d_09.jpg);
  transform: rotateX(180deg) rotateZ(180deg) translateZ(150px);
}
.minbox li:nth-child(3){
  // background: url(../assets/img/a3d_07.jpg);
  transform: rotateX(-90deg) translateZ(150px);
}
.minbox li:nth-child(4){
  // background: url(../assets/img/a3d_04.jpg);
  transform: rotateX(90deg) translateZ(150px);
}
.minbox li:nth-child(5){
  // background: url(../assets/img/a3d_08.jpg);
  transform: rotateY(-90deg) translateZ(150px);
}
.minbox li:nth-child(6){
  // background: url(../assets/img/a3d_02.jpg);
  transform: rotateY(90deg) translateZ(150px);
}
@keyframes move{
  0%{
    transform: rotateX(18deg) rotateY(0deg);
  }
  100%{
    transform: rotateX(18deg) rotateY(360deg);

  }
}

@media (max-width: 550px) {
  .box {
    width: 150px !important;
    height: 150px !important;
    margin: 20px 100px 100px 120px !important;
  }  
  .minbox {
    width: 150px !important;
    height: 150px !important;
  }
  .minbox li{
    width: 150px !important;
    height:  150px !important;
    background-size: 200px 200px !important;
  }
  .minbox li:nth-child(1){
    transform: translateZ(75px) !important;
  }
  .minbox li:nth-child(2){
    // background: url(../assets/img/a3d_09.jpg);
    transform: rotateX(180deg) rotateZ(180deg) translateZ(75px);
  }
  .minbox li:nth-child(3){
    // background: url(../assets/img/a3d_07.jpg);
    transform: rotateX(-90deg) translateZ(75px);
  }
  .minbox li:nth-child(4){
    // background: url(../assets/img/a3d_04.jpg);
    transform: rotateX(90deg) translateZ(75px);
  }
  .minbox li:nth-child(5){
    // background: url(../assets/img/a3d_08.jpg);
    transform: rotateY(-90deg) translateZ(75px);
  }
  .minbox li:nth-child(6){
    // background: url(../assets/img/a3d_02.jpg);
    transform: rotateY(90deg) translateZ(75px);
  }
}
</style>