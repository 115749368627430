<template>
  <div class="sect-container">
    <el-row  :gutter="40" justify="center">
      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="upper show">
          <div class="box">
            <el-image :src="pic_list[0].src" alt=""></el-image>
          </div>
        </div>
        <div class="content show">
          <h3>{{pic_list[0].title}}</h3>
          <p>{{pic_list[0].desc}}</p>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="upper">
          <div class="box">
            <el-image :src="pic_list[1].src" alt=""></el-image>
          </div>
        </div>
        <div class="content">
          <h3>{{pic_list[1].title}}</h3>
          <p>{{pic_list[1].desc}}</p>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
        <div class="upper show">
          <div class="box">
            <!-- <div class="movie"></div> -->
            <img src="../assets/img/havefun_s.gif" />
          </div>
        </div>
        <div class="content show">
          <h3>{{pic_list[2].title}}</h3>
          <p>{{pic_list[2].desc}}</p>
        </div>
      </el-col>  
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pic_list:[
        {
          title: '1. Who we are',
          desc: `Our logo, zoea, resembles the letter 'T' in shape, which not only represents Dr.Tim alone, but also a group of scientists and engineers who consistently pursue 'Truth' throughout their lives. `,
          desc_zh: 'logo中的T字母形象，代表的不仅仅是Dr.Tim一个人，而是一群具有共同理念和价值观、有着强烈和持久热爱的跨领域的科学家和工程师。',
          src: require("../assets/img/who-we-are.jpg"),
        },
        {
          title: '2. What do we do',
          desc: `Our team consists of people from all fields--Life Sciences, Environmental Sciences, Physics, Mathematics, Engineering and Artificial Intelligence... Nonetheless, we share the same goal of using all the means at our disposal to tackle scientific questions vital for human future.`,
          desc_zh: '我们来自不同的行业和领域，生命科学，环境科学，物理、数学、计算机、人工智能，但是都有着一个共同的目标，就是努力使用所有可以触及的手段，去尽可能揭示那些微小不为人所知，却又对未来影响巨大的那些科学问题。',
          src: require("../assets/img/havefun.jpg"),
        },
        {
          title: '3. Find truth and have fun',
          desc: `Marine plankton and AI Deep Learning are 2 fields currently under intensive researchs, with lots of questions waiting to be answered. We aim to make Science more interesting and fun for the public. We welcome more young people to join us and explore this amazing field together.`,
          desc_zh: '海洋浮游生物领域目前是前沿研究领域，有大量的科学问题等待我们去回答。我们希望让更多人了解真正的科学研究，让科研变得有趣。欢迎更多的年轻人加入我们，一起探索和发现。',
          src: require("../assets/img/havefun_s.gif"),
        },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.sect-container{
  margin: 20px 5%;
  padding: 20px 5%;
  // max-width: 1500px;
  // margin-bottom: 80px;
}
.upper {
    display: flex;
    justify-content: center;
    width: 100%;
}
.box {
  width: 90%;
  img{
    padding: 0 ;
    margin: 50px 0;
    width: 350px;
    height: 300px;
    border-style: solid;
    border-color: blue; 
    border-radius: 10px;
    box-shadow: 0 15px 50px rgba(0,0,0,0.2);
    transform: rotate(90deg);
  }
}
.movie-box{
  width: 50%;
}
.movie{
  margin: 50px 0;
  // background: url(../assets/img/havefun_s.gif);
  // background-size:100% 100%;
}
.content{
  text-align: left;
  display: none;
}
@media (max-width: 500px) {
  .show{
    display: none;
  }
}
</style>