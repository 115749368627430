<template>
  <div class="container">
    <el-row :gutter="20" justify="center">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
        v-for="(pic, i) in pic_list" :key="i"
      >
      <div class="images">
        <div class="upper" @click="$router.push('/forauthors')"> 
          <div class="box">
            <el-image :src="pic.src" alt=""></el-image>
            <div style="font-size:12px"><a class="txt-link" href="/forauthors"> Author submission requirements >></a></div>
          </div>
        </div>
      </div>
        <div class="content">
          <h3>{{pic.title}}</h3>
          <p>{{pic.desc}}</p>
        </div>
      </el-col>
    </el-row>

    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      width="90%"
      >
      <el-image :src="currPic" alt="" @click="dialogVisible = false"></el-image>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      currPic: '',
      pic_list:[
        {
          title: '',
          desc: '',
          src: require("../assets/img/ysa/TYS-123.jpg"),
        },
        // {
        //   title: '',
        //   desc: '',
        //   src: require("../assets/img/ysa/ysa_i1a8_rose-1-s.jpg"),
        // },
        // {
        //   title: '',
        //   desc: '',
        //   src: require("../assets/img/ysa/ysa_i1a7_spark-1-s.jpg"),
        // },
        // {
        //   title: '',
        //   desc: '',
        //   src: require("../assets/img/ysa/ysa_i1a3-5-s.jpg"),
        // },
      ]
    }
  },

  methods:{
    clickShowBigPic(i){
      
      // console.log(i)
      this.currPic = this.pic_list[i].src
      this.dialogVisible = true;
    },    
    handleClose(done) {
      done() ;
      return ;
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
  }
}
</script>

<style lang="less" scoped>
.container{
  text-align: center;
  margin: 0px 5px;
  // padding: 20px 10%;
  // max-width: 1500px;
  justify-content: center;
}
.images{
  .upper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.box {
  width: 100%;
}
.content{
  text-align: center;
}
.dialog-footer{
  display: none;
}
/deep/ .el-dialog__header{
  padding: 0 0 ;
}
/deep/ .el-dialog__body{
  padding: 0 0 ;
}
</style>